.loginSliderTextOuter{
	width: 100px;
	border: double 1px transparent;
	border-radius: 18px;
	background-image: linear-gradient(#0388dd, #0388dd), 
	            linear-gradient(to right, #FBFF27, #00D662);
	background-origin: border-box;
	background-clip: content-box, border-box;
	text-align: center;
	height: 27px;
}

.loginSliderText {
	width: 100px;
	margin-top: 5px;
}
.loginSliderText div{
	background-image: linear-gradient(to right, #F08D19, #00D662);
	color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	font-size: 0.9rem;
	width: 100px;
	text-align: center!important;
	height: 20px;
	font-weight: bold;
}
.copyright-text {
	font-size: 0.9rem!important;
}
@media only screen and (min-width: 320px) and (max-width: 1679px) {
	.login-box-left {
		margin-top: 20px!important;
	}
	.copyright-text {
		font-size: 0.7rem!important;
	}
}