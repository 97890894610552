body {
    margin: 0;
    font-family: 'Lato', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    position: relative;
}

.common-space-container {
    width: 95%;
    margin: 0 auto;
}

.center-aligned {
    display: flex;
    align-items: center;
    justify-content: center;
}

.vertically-center-aligned {
    display: flex;
    align-items: center;
}

.right-align-content {
    display: flex;
    justify-content: flex-end
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.even {
    background-color: #F9FAFF;
}

.data-grid-header .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
    font-family: Lato;
}

.loto-family {
    font-family: Lato;
}


.tab-label {
    font-family: Lato !important;
    font-size: 0.85rem !important;
    font-weight: 400 !important;
    line-height: 14.4px !important;
    text-align: left;
}

.tabs-container .MuiTabs-indicator {
    display: none;
}

.tab-subcategory {
    font-family: Lato !important;
    font-size: 0.8rem !important;
    line-height: 14.4px !important;
}

.tab-subcategory.Mui-selected {
    background-color: #f9faff;
    color: #3854e4 !important;
    font-weight: 700 !important;
}

.tab-sidebar .MuiTabs-indicator {
    display: none;
}

.tab-sidebar-item.Mui-selected {
    background-color: #f9faff;
    border: 1px solid #eeeeee;
}

.tab-sidebar-label {
    font-size: 12px !important;
    line-height: 14.4px !important;
    text-align: left !important;
}

.Mui-selected .tab-sidebar-label {
    font-weight: 700 !important;
}
.tab-sidebar-label-selected {
    font-weight: 700 !important;
}

.tab-sidebar-outer-selected {
    background-color: #f9faff;
    border: 1px solid #eee;
    color: rgb(56, 84, 228);
}

.sku-desc p {
    font-size: 0.9rem;
}

.sku-desc .more-button {
    font-size: 0.91rem;
}

.estimation-block .selected-item, .e-catalog-block .selected-item {
    background-color: #eee;
}
.data-grid-right-aligned-header .MuiDataGrid-columnHeaderTitle {
    margin-right: 16px;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

.break-word-anchor {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;
}
.price-search-selected {
    color: blue!important;
}
.right-align-text {
    text-align: right;
}
.sku-list-image {
    width: 50px;
    height: 50px;
    padding-right: 8px;
    padding-top: 8px;
    padding-bottom: 8px;
    object-fit: contain;
}
.anchor-color {
    color: #3854E4;
}