.home-box-title-outer {
    padding: 25px 45px 42px;
    border-radius: 7px 7px 0 0;
    border-bottom: 1px solid #f2f0f0;
    height: 50px;
    margin-bottom: 10px;
    text-align: left;
    background: #ECEAEA26;
}

.home-box-title {
    font-family: Lato;
    font-size: 1rem !important;
    font-weight: 900 !important;
    line-height: 19.2px !important;
    letter-spacing: -0.019999997690320015px;
    text-align: left;
    color: #1E2B51;
}

.home-box-elem-title {
    font-size: 13px !important;
    font-family: Lato !important;
    margin-bottom: 10px !important;
    text-align: left;
    font-weight: 500 !important;
    line-height: 15.6px;
    color: #768698;
    position: relative;

}

.red-next-icon {
    font-size: 12px !important;
    position: absolute;
    color: red;
    top: 3px;
    margin-left: 10px !important;
    font-weight: 300;
}

.home-box-elem-count {
    /* font-family: Barlow !important;  */
    font-size: 32px !important;
    margin-bottom: 16px !important;
    text-align: left;
    line-height: 38.4px !important;
    font-weight: 700 !important;
    letter-spacing: -0.019999997690320015px !important;
    color: #525983;

}

.home-box-bottom-title {
    font-size: 0.8rem !important;
    margin-bottom: 16px !important;
    text-align: center;
    font-weight: bold !important;
    color: #3854e4;
    line-height: 70px !important;
    position: relative;
    cursor: pointer;
}

.blue-next-icon {
    font-size: 12px !important;
    position: absolute;
    color: #3854e4;
    top: 30px;
    margin-left: 7px !important;
    font-weight: 300 !important;
}

.customised_table_view .css-1iyq7zh-MuiDataGrid-columnHeaders {
    background: #e6edf7 !important;
}

.customised_table_view .css-1wujlsp-MuiDataGrid-root .css-1iyq7zh-MuiDataGrid-columnHeaders {
    background-color: #e6edf7 !important;
}

.customised_table_view .css-1gvsc43-MuiTablePagination-displayedRows,
.customised_table_view .home_summary_table .css-1gvsc43-MuiTablePagination-displayedRows {
    display: none !important;
}

.customised_table_view .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar,
.customised_table_view .home_summary_table .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    position: unset !important;
}

.customised_table_view .css-zylse7-MuiButtonBase-root-MuiIconButton-root,
.customised_table_view .home_summary_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root {
    position: absolute !important;
    top: 50%;
    right: -45px;
    margin-top: -13px !important;
    background-color: #e6edf7 !important;
    padding: 3px !important;
}

.customised_table_view .css-zylse7-MuiButtonBase-root-MuiIconButton-root:first-child,
.customised_table_view .home_summary_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root:first-child
{
    left: -45px !important;
    right: unset !important;
}

.customised_table_view .css-zylse7-MuiButtonBase-root-MuiIconButton-root:last-child,
.customised_table_view .home_summary_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root:last-child {
    right: -45px !important;
}

.customised_table_view .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled:first-child,
.customised_table_view .home_summary_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled:first-child {
    left: -45px !important;
    right: unset !important;
}

.customised_table_view .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled:last-child,
.customised_table_view .home_summary_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled:last-child {
    right: -45px !important;
}

.customised_table_view .css-wop1k0-MuiDataGrid-footerContainer,
.customised_table_view .home_summary_table .css-wop1k0-MuiDataGrid-footerContainer {
    height: 0px !important;
    min-height: 0px !important;
}

.customised_table_view .css-de9k3v-MuiDataGrid-selectedRowCount,
.customised_table_view .home_summary_table .css-de9k3v-MuiDataGrid-selectedRowCount {
    display: none !important;
}

.customised_table_view .css-zylse7-MuiButtonBase-root-MuiIconButton-root .css-i4bv87-MuiSvgIcon-root,
.customised_table_view .home_summary_table .css-zylse7-MuiButtonBase-root-MuiIconButton-root .css-i4bv87-MuiSvgIcon-root {
    width: 0.7em !important;
    height: 0.7em !important;
}

.deviderSkus {
    height: 162px;
    width: 1px;
    margin-top: 15%;
    margin-left: 2%;
    color: #f8f8f8;
    border: 0.5px solid #d5d5d5;
}

.sku_overview_selection {
    display: inline-block;
    width: 89px;
    text-align: center;
    background: #e5f4ff;
    padding: 8px 5px;
    border-radius: 13px;
    margin-left: 9px !important;
}

.stack-chart-container {
    height: 215px;
}

@media only screen and (min-width: 320px) and (max-width: 1439px) {
    .home-box-elem-title {
        font-size: 0.7rem !important;
        margin-bottom: 16px !important;
        text-align: left;
        font-weight: 600 !important;
        color: #999;
        position: relative;
    }

    .red-next-icon {
        font-size: 10px !important;
        position: absolute;
        color: red;
        top: 2px;
        margin-left: 8px !important;
        font-weight: 300;
    }

    .home-box-elem-count {
        font-size: 1.5rem !important;
        margin-bottom: 16px !important;
        text-align: left;
        font-weight: 900 !important;
    }
}

@media only screen and (min-width: 1680px) and (max-width: 1919px) {
    .stack-chart-container {
        height: 218px;
    }
}

@media only screen and (min-width: 1920px) {
    .stack-chart-container {
        height: 235px;
    }
}

.badge-circle {
    width: 10px;
    height: 10px;
    display: inline-flex;
    border-radius: 50%;
}

.project-chart-title {
    color: #768698;
    font-size: 15px!important;
    line-height: 14px!important;
    font-family: Lato!important;
}

.project-chart-percentage {
    font-weight: 700!important;
    font-size: 19.01px!important;
    line-height: 22.81px!important;
    letter: -0.02px!important;
    color: #525983;
    margin-left: 16px!important;
}