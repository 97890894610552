
.catalogue_summary_view .cat_summary_selection {
    display: inline-block;
    width: auto;
    text-align: center;
    padding: 8px 25px;
    border-radius: 13px;
    margin-left: 20px!important;
}
.catalogue_summary_view .cat_summary_selection.selected {
	background: #e5f4ff;
	color: #3854e4;
	font-weight: bold;
}

.catalogue_summary_view .cat_summary_selection.disabled {
    background: #f8f8f8;
    color: #333;
    cursor: pointer;
}

.catalogue_summary_view .css-1e68cha-MuiButtonBase-root-MuiListItemButton-root, .catalogue_summary_view .css-wabxg6-MuiButtonBase-root-MuiListItemButton-root {
	border-left: 5px solid transparent!important;
	padding-top: 15px!important;
	padding-bottom: 15px!important;
	border-top: 1px solid #f8f8f8!important;
	border-bottom: 1px solid #f8f8f8!important;
}
.catalogue_summary_view .css-1e68cha-MuiButtonBase-root-MuiListItemButton-root.Mui-selected, .catalogue_summary_view .css-wabxg6-MuiButtonBase-root-MuiListItemButton-root.Mui-selected, .catalogue_summary_view .selected-list {
	border-left: 5px solid #048cdc!important;
	background: #e5f4ff!important;
}

.catalogue_summary_table {
	width: 100%;
}

.summary_caterogy_detail_item {
	margin-right: 15%;
	margin-top: 32px;
	width: 174px;
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
	.customised_table_view {
		max-width: 665px;
		margin: 32px;
	}
	.cat_sum_subcat_tabs .subcategory_summary_tab_list {
		max-width: 280px;
		margin: 0 32px;
	}
	.summary_caterogy_detail_item {
		margin-right: 12%;
	}
}
@media only screen and (min-width: 1440px) and (max-width: 1679px) {
	.customised_table_view {
		max-width: 665px;
	}
	.summary_caterogy_detail_item {
		margin-right: 12%;
	}
	.cat_sum_subcat_tabs .subcategory_summary_tab_list {
		max-width: 360px!important;
	}

}
@media only screen and (min-width: 1680px)  and (max-width: 1919px){
	.summary_caterogy_detail_item {
		margin-right: 17%;
	}

	.cat_sum_subcat_tabs .subcategory_summary_tab_list {
		max-width: 360px!important;
	}
}

@media only screen and (min-width: 1680px) {
	.summary_caterogy_detail_item {
		margin-right: 17%;
	}

	.cat_sum_subcat_tabs .subcategory_summary_tab_list {
		max-width: 425px;
	}
}